.dataGrid {
  & :global(.MuiButton-label) {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #111;
  }
}

.xsButton {
  line-height: 12px !important;
  font-size: 12px !important;
  border-radius: 25px !important;
}

.retryBtn:hover {
  text-decoration: underline;
}
