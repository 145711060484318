.createPPIModal {
  aside {
    z-index: 101;
  }
  aside > div {
    //eds_modal
    width: 50%;
  }
  //.eds-scrim> .eds_modal >. eds_modal__header
  aside > div > div:nth-child(1) {
    justify-self: end;
  }
  //.eds-scrim> .eds_modal >. eds_modal__content
  aside > div > div:nth-child(2) {
    max-width: 100%;
  }
  aside > div > div:nth-child(2) > div {
    padding: var(--eds-space-8);
    padding-bottom: 74px;
  }
}
