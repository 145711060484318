.answerOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.optionHeadersWrapper {
  position: absolute;
  top: 30px;
  right: 0;
  span {
    color: #707072;
  }
}

.optionHeaders {
  display: flex;
  gap: 16px;
  align-items: center;
}

.advancedModeToggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
