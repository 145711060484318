.surveyGrid {
  margin-top: 24px;
}

.surveyHandleGrid {
  margin-bottom: -24px !important;
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.form {
  min-width: 300px;
  width: 100%;
  align-items: flex-start;
  margin: auto 16px;
}

fieldset {
  border: none;
}

.rowWrapper {
  border: 1px solid #d6d6d6;
  margin: 20px 0;
  padding: 20px 10px 0 10px;
}

.greyOut {
  background-color: #dedede !important;
}

.gridTitle {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin: 40px 0 12px 0;
}

.accordionPanel {
  background-color: #f8f8f8;
  padding: 0 28px;
  summary {
    span {
      position: relative;
      span {
        position: absolute;
        left: -37px;
        top: 3px;
      }
    }
  }
}

.accordionPanelMargin {
  margin: 24px 0;
}

.detailsTitle {
  width: fit-content;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--eds-space-8);
  button {
    white-space: pre;
    &:focus-visible {
      outline: none;
    }
    &:focus {
      border: none;
    }
  }
}

.fieldSetGrid {
  overflow-y: unset;
}

.layout {
  position: relative;
}

.conditionalLayout {
  padding-left: 24px;
  border-left: 1px solid #707072;
  position: relative;
}

.addItemBtn {
  margin: 24px 0px 16px 0px;
}

.answerOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleSurveyHeader,
.toggleScreenerHeader {
  position: absolute;
  top: 30px;
  right: 2px;
  span {
    color: #707072;
  }
}

.toggleScreenerHeader {
  top: 0px !important;
}

.optionHeaders {
  display: flex;
  gap: 18px;
}

.consentRemoveLink {
  margin-top: 11px;
  float: right;
  cursor: pointer;
  padding-right: 4px;
  color: var(--eds-color-border-primary);
  font: var(--eds-type-subtitle-2);
}

.selectFieldWrapper {
  position: relative;
}

.editCategoryContentBtn {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 2px;
  button {
    color: var(--eds-color-grey-1);
    text-decoration: underline;
  }
}

.advancedModeToggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.conditionalSelectSubtitle {
  margin-top: 4px;
  span,
  select {
    color: var(--eds-color-text-secondary);
    font: var(--eds-type-body-2);
  }
}
