.guideModal {
  div {
    max-width: 800px !important;
  }
}

.guideTable {
  width: 100%;
  thead {
    tr {
      th {
        min-width: 280px;
      }
      th:last-of-type {
        min-width: 160px;
      }
    }
  }
}

.guideLink {
  word-break: break-word;
  a {
    color: var(--eds-color-text-default);
    font-weight: 500;
  }
}

.copyButton {
  text-align: left;
}

.copyButton:focus,
.copyButton:active {
  border: none !important;
}

.note {
  word-break: break-word;
}
