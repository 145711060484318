.answerOptionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;

  .ansOptsFieldsContainer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }

  // AnswerOption Label/Name Input Field
  .ansOptsFieldsContainer > *:nth-child(1) {
    flex: 33;
  }
  // AnswerOption Value Input Field
  .ansOptsFieldsContainer > *:nth-child(2) {
    flex: 33;
  }
  // AnswerOption Description Input Field
  .ansOptsFieldsContainer > *:nth-child(3) {
    flex: 33;
  }

  // Toggle & Delete icon container
  .ansOptsFieldsContainer > *:nth-child(4) {
    flex: 1;
  }

  /* When only two children exists ( [ Input field ] & [ toggle/delete icon] ) */
  .ansOptsFieldsContainer > *:nth-child(1):nth-last-child(2) {
    flex: 80;
  }
  .ansOptsFieldsContainer > *:nth-child(2):last-child {
    flex: 12;
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 14px;

    button {
      &:hover {
        cursor: pointer;
      }

      &:disabled {
        color: var(--eds-color-text-link-disable);
        cursor: not-allowed;
      }
    }
  }

  .toggleButton:only-child {
    margin-top: 15px;
  }

  // for hiding the *
  > div > div {
    label {
      display: none;
    }
  }

  // for wrapping the duplicate error message
  > div > div > div:nth-last-child(1) {
    p {
      word-break: break-word;
    }
  }
}

// AnswerOption Label/Name Input Field
.ansOptsFieldsContainer > *:nth-child(1) {
  flex: 33;
}
// AnswerOption Value Input Field
.ansOptsFieldsContainer > *:nth-child(2) {
  flex: 33;
}
// AnswerOption Description Input Field
.ansOptsFieldsContainer > *:nth-child(3) {
  flex: 33;
}

// Toggle & Delete icon wrapper
.ansOptsFieldsContainer > *:nth-child(4) {
  flex: 1;
}

.addAnswerOptionBtn {
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid #cacacb;
  &[disabled] {
    cursor: not-allowed;
  }
}

.textFieldStyle {
  :focus {
    border-color: #707072 !important;
  }
}

.answerButtonsWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  align-items: center;
  flex-wrap: nowrap !important;
  gap: 12px;
}

.answerButtonsWrapper > *:last-child:only-child {
  justify-content: flex-end;
  margin-left: auto;
}

.toggleButton:only-child {
  margin-top: 15px;
}

.addAnswerOptionsContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
